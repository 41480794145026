
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import * as React from 'react';
import * as firebaseSdk from 'sdk/firebase';
import * as TestySDK from 'sdk/testy-web-sdk';
import * as AuthUtils from 'utils/auth/auth';
import * as T from 'components/ui/typography';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useFormik } from 'formik';
import { ConfirmationResult } from 'firebase/auth';
import Button from 'components/shared/button';
import FormTextInput from 'components/shared/form-field-input';
import { LoginSchema, passwordSchema } from 'utils/form/validation-schema';
import { AuthContext } from 'providers/auth-provider';
import Routes from 'utils/routes';
import { useAppConfig } from 'hooks/features/use-features';
import RecoveryPasswordModal from 'components/shared/recovery-password-modal';
import useTranslation from 'next-translate/useTranslation';
import LoginWelcomeMessage from 'components/shared/login-welcome-message';
import LoginFormLayout from 'components/layout/login-form-layout';
import LinkButton from 'components/shared/link-button';
import { analytics } from 'utils/analytics';
import IconButton from 'components/shared/icon-button';
import { CheckCircle2, Eye, EyeOff } from 'lucide-react';
export default function LoginPage() {
    const router = useRouter();
    const { appConfig } = useAppConfig();
    const { t } = useTranslation('common');
    const [loading, setLoading] = React.useState(false);
    const { setUser } = React.useContext(AuthContext);
    const [identifierVerified, setIdentifierVerified] = React.useState(false);
    const [identifierHelperText, setIdentifierHelperText] = React.useState('');
    const [phoneConfirmationResult, setPhoneConfirmationResult] = React.useState<ConfirmationResult | undefined>();
    const [phoneOTPCodeHelperText, setPhoneOTPCodeHelperText] = React.useState('');
    const [passwordHelperText, setPasswordHelperText] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const validationSchema = React.useMemo(() => {
        if (!identifierVerified)
            return LoginSchema;
        else
            return passwordSchema;
    }, [identifierVerified]);
    const form = useFormik({
        initialValues: {
            identifier: '',
            password: '',
            phoneOTPCode: '',
            phone: '',
        },
        onSubmit: () => {
            onSubmit();
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });
    const isPhoneIdentifier = React.useMemo(() => {
        const reg = new RegExp('^[+][0-9]{2}[0-9]{10}$');
        const reg2 = new RegExp('^[0-9]{10}$');
        if (reg.test(form.values.identifier))
            return true;
        if (reg2.test(form.values.identifier))
            return true;
        else
            return false;
    }, [form.values.identifier]);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const verifyUserWithEmailExists = async () => {
        TestySDK.checkUserExistance({ email: form.values.identifier })
            .then(() => {
            setIdentifierVerified(true);
            setIdentifierHelperText('');
        })
            .catch((e) => {
            if (e.response) {
                if (e.response.status === 404) {
                    setIdentifierHelperText(t('errors.noUser'));
                }
                else
                    setIdentifierHelperText(e.message);
            }
            else {
                setIdentifierHelperText(e.message);
            }
            setLoading(false);
        })
            .finally(() => setLoading(false));
    };
    const verifyUserWithPhoneExists = async () => {
        TestySDK.checkUserExistance({ phone_number: form.values.identifier })
            .then(() => {
            firebaseSdk.signWithPhoneNumber(form.values.identifier).then((res) => {
                setIdentifierVerified(true);
                setPhoneConfirmationResult(res);
            });
        })
            .catch((e) => {
            if (e.response) {
                if (e.response.status === 404) {
                    setIdentifierHelperText('Nessun utente con questo numero di telefono');
                }
                else
                    setIdentifierHelperText(e.message);
            }
            else {
                setIdentifierHelperText(e.message);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const signInWithPhone = async () => {
        if (!phoneConfirmationResult)
            throw new Error('Phone confirmation result is undefined');
        firebaseSdk
            .checkPhoneVerificationCode(form.values.phoneOTPCode, phoneConfirmationResult)
            .then(async (user) => {
            const idToken = await user.user.getIdToken();
            const token = await AuthUtils.exchangeFirebaseTokenWithCustom(idToken);
            const userBasicData = AuthUtils.userBasicDataParser(user.user, token);
            TestySDK.setAuthToken(userBasicData.token);
            setUser(userBasicData);
            router.push(Routes.Home);
        })
            .catch((e) => {
            setPhoneOTPCodeHelperText(e.message);
        })
            .finally(() => setLoading(false));
    };
    const signInWithCredentials = async (email: string, password: string) => {
        try {
            const user = await firebaseSdk.signInWithEmailAndPassword(email, password);
            const idToken = await user.getIdToken(true);
            const token = await AuthUtils.exchangeFirebaseTokenWithCustom(idToken);
            const userBasicData = AuthUtils.userBasicDataParser(user, token);
            await analytics.identify(`${userBasicData.email}`, userBasicData);
            setUser(userBasicData);
            analytics.track('login_success');
            TestySDK.setAuthToken(userBasicData.token);
            router.push(Routes.Home);
        }
        catch (e) {
            analytics.track('login_error');
            setPasswordHelperText(t('errors.passwordIncorrect'));
            setLoading(false);
        }
    };
    const onSubmit = async () => {
        setLoading(true);
        if (!identifierVerified) {
            if (!isPhoneIdentifier)
                await verifyUserWithEmailExists();
            else
                await verifyUserWithPhoneExists();
            return;
        }
        if (!form.values.phoneOTPCode)
            await signInWithCredentials(form.values.identifier, form.values.password);
    };
    const handleErrorIdentifier = React.useMemo(() => {
        if (form.errors.identifier)
            return t(`${form.errors.identifier}`);
        if (identifierHelperText)
            return identifierHelperText;
        else
            return '';
    }, [form.errors.identifier, identifierHelperText, t]);
    const handleErrorPassword = React.useMemo(() => {
        if (form.errors.password)
            return t(`${form.errors.password}`);
        if (passwordHelperText)
            return passwordHelperText;
        else
            return '';
    }, [form.errors.password, passwordHelperText, t]);
    return (<LoginFormLayout>
            <div>
                <Head>
                    <title>Accedi</title>
                </Head>
                <LoginWelcomeMessage />

                <div id="recaptcha-container"></div>

                <FormTextInput value={form.values.identifier} data-testid="email" error={handleErrorIdentifier} onChangeFn={form.handleChange('identifier')} placeholder={t('identifier')} disabled={identifierVerified} endAdornment={identifierVerified ? <CheckCircle2 className="mx-2 fill-primary text-white"/> : <></>}/>

                {/** Password field */}
                {!isPhoneIdentifier && identifierVerified ? (<FormTextInput value={form.values.password} data-testid="password" error={handleErrorPassword} onChangeFn={form.handleChange('password')} placeholder={t('insertPass')} type={showPassword ? 'text' : 'password'} password endAdornment={<IconButton onClick={handleClickShowPassword} onMouseDown={(e) => e.preventDefault()}>
                                {!showPassword ? (<Eye className="h-5 w-5 text-neutral-600"/>) : (<EyeOff className="h-5 w-5 text-neutral-600"/>)}
                            </IconButton>}/>) : null}

                {/** Phone OTP field */}
                {isPhoneIdentifier && phoneConfirmationResult ? (<FormTextInput value={form.values.phoneOTPCode} error={phoneOTPCodeHelperText} onChangeFn={form.handleChange('phoneOTPCode')} placeholder="Codice OTP"/>) : null}

                <RecoveryPasswordModal trigger={<LinkButton containerClassName="my-2" dataCy="change_mod_login">
                            <u> {t('passwordForgot')}</u>
                        </LinkButton>} identifier={form.values.identifier} setIdentifier={form.handleChange('identifier')}/>

                <Button data-testid={'login-button'} className="w-full mt-1" loading={loading} onClick={form.values.phoneOTPCode ? () => signInWithPhone() : () => form.handleSubmit()}>
                    Continua
                </Button>

                {!appConfig.feature.includes('restrictedSignup') ? (<>
                        <div className="flex items-center mt-3 ml-1 gap-1">
                            <T.Muted>{t('noAccount')}</T.Muted>
                            <LinkButton onClick={() => router.push(Routes.SignUp)} dataCy="change_mod_login">
                                {t('SignUp')}
                            </LinkButton>
                        </div>
                    </>) : null}
            </div>
        </LoginFormLayout>);
}

    async function __Next_Translate__getStaticProps__18b044a6930__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18b044a6930__ as getStaticProps }
  